<template>
  <vx-card title="Customer Visit Plan">
    <div class="vx-row mb-12 col-12">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <data-table-sales :baseUrl="this.baseUrl"></data-table-sales>
      </div>
    </div>
  </vx-card>
</template>

<script>
import DataTableSales from "./DataTableSales.vue";
export default {
  components: {
    DataTableSales,
  },
  data() {
    return {
      baseUrl: "/api/v1/sales-force-automation/customer-visit-plan",
    };
  },
  methods: {},
  mounted() {},
  watch: {},
};
</script>
